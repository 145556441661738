.phoneNumberContainer {
    // "+"
    > :first-child {
        position: relative;
        top: 50px; // we have addition of vertical space because of labels causing us to add magic number.
        flex: 0 1 auto;
    }

    // country code
    > * + * {
        margin-left: 16px;
        flex: 0 0 25%;
    }

    // phone number
    > :last-child {
        flex: 1 1 auto;
    }
}
