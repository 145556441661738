// overrides
$control-border-width: $rds-unit-size-1;
$control-height: 40px;
$control-line-height: 16px;
$control-padding-horizontal: $rds-spacing-size-24;
$control-padding-vertical: 0;
$control-radius: map-get($border-radiuses, 4);

// variables
$control-disabled-opacity: 0.35;
$control-font-family: $family-sans-serif;
$control-font-size: 14.22px;
$control-font-weight: $weight-bold;
$control-hover-neutral-background: $grey-lightest;
$control-neutral-background: $white;
$control-neutral-border: $grey;
$control-neutral-on-background: $grey-darker;

$control-height-small: 24px;
$control-font-size-small: 12.64px;
