// NOTE This file should only be used to override CSS that comes with RDS to fix bugs until a proper fix is shipped
@import '../helpers/mixins';

// NOTE Fix for RDS button focus highlight styles. Firefox applies the newer :focus-visible pseudo-class when tabbing
// focus which results in the default dotted outline showing on focus.
.rds-primary-button,
.rds-primary-alt-button,
.rds-secondary-button,
.rds-tertiary-button {
    &:focus {
        box-shadow: none;
    }

    @include focus-highlight {
        outline: none;
    }
}

.rds-primary-button {
    @include focus-highlight {
        box-shadow: 0 0 0 1px #fff, 0 0 0 4px #0096dc;
    }
}

.rds-primary-alt-button {
    @include focus-highlight {
        box-shadow: 0 0 0 3px #0096dc;
    }
}

.rds-secondary-button {
    @include focus-highlight {
        box-shadow: 0 0 0 1px #fff, 0 0 0 4px #0096dc;
    }
}

.rds-tertiary-button {
    @include focus-highlight {
        box-shadow: 0 0 0 1px #fff, 0 0 0 4px #0096dc;
    }
}

.rds-link-ext-footer,
.rds-link-ext-inline,
.rds-link-sa,
.rds-link-heading,
.rds-link-icon-left,
.rds-link-inline,
a {
    @include link-focus-highlight(3px, #0096dc);
}
