@mixin add-font($family, $weight, $style, $file-name) {
    @at-root {
        @font-face {
            font-family: $family;
            font-weight: $weight;
            font-style: $style;
            font-display: swap;
            // NOTE `~` prefix is powered by css-loader https://webpack.js.org/loaders/css-loader/#url
            // It removes the need to set an env-based prefix
            src: url('~src/assets/fonts/#{$family}/#{$file-name}.woff') format('woff');
        }
    }
}

$font-family: 'Frutiger';
// NOTE Duplicating font file names across weights since UHCSans supports more weights than Frutiger
// so that we can explicitly set which fonts to use for the weights
$fonts: (
    // Main weight
    $font-family 700 normal 'FrutigerLTStd-Bold',
    $font-family 600 normal 'FrutigerLTStd-Bold',
    $font-family 500 normal 'FrutigerLTStd-Roman',
    // Main weight
    $font-family 400 normal 'FrutigerLTStd-Roman',
    // Main weight
    $font-family 300 normal 'FrutigerLTStd-Light',
    $font-family 200 normal 'FrutigerLTStd-Light',
    $font-family 100 normal 'FrutigerLTStd-Light'
);

@each $font in $fonts {
    $family: nth($font, 1);
    $weight: nth($font, 2);
    $style: nth($font, 3);
    $file-name: nth($font, 4);

    @include add-font($family, $weight, $style, $file-name);
}

// Override all font-family settings in UHC theme
body,
button,
input,
select,
optgroup,
textarea,
em,
i,
strong,
b,
small,
.is-rds-h1,
.is-rds-h2,
.is-rds-h3,
.is-rds-h4,
.is-rds-h5,
.is-rds-h6,
.is-rds-body-1,
.is-rds-body-2,
.is-rds-body-3,
.is-rds-sm,
.is-rds-micro {
    font-family: $font-family, Helvetica, Arial, sans-serif;
}
