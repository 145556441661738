// Margin Bottom
@mixin apply-spacing-margin-bottom($size: 'size-8') {
  margin-bottom: map-get($spacing-sizes, $size);
}

@mixin apply-spacing-margin-bottom-touch($size: 'size-8') {
  @include touch {
    margin-bottom: map-get($spacing-sizes, $size);
  }
}
// Margin Right
@mixin apply-spacing-margin-right($size: 'size-8') {
  margin-right: map-get($spacing-sizes, $size);
}

@mixin apply-spacing-margin-right-touch($size: 'size-8') {
  @include touch {
    margin-right: map-get($spacing-sizes, $size);
  }
}
// Margin Left
@mixin apply-spacing-margin-left($size: 'size-8') {
  margin-left: map-get($spacing-sizes, $size);
}

@mixin apply-spacing-margin-left-touch($size: 'size-8') {
  @include touch {
    margin-left: map-get($spacing-sizes, $size);
  }
}
// Padding
@mixin apply-spacing-padding($size: 'size-8') {
  padding: map-get($spacing-sizes, $size);
}

@mixin apply-spacing-padding-touch($size: 'size-8') {
  @include touch {
    padding: map-get($spacing-sizes, $size);
  }
}

@mixin u-create-spacing-properties($property, $value) {
  $axis-positions: (
    'x': left right,
    'y': top bottom
  );

  // margin-x, margin-y, padding-x, padding-y
  @if (str-index($property, '-x') or str-index($property, '-y')) {
    $axis: str-slice($property, str-length($property));

    // 1-4 spacing values
    @if (type-of($value) == 'list') {
      $positions: map-get($axis-positions, $axis);

      @each $position in $positions {
        $i: index($positions, $position);
        #{str-slice($property, 1, -3)}-#{$position}: nth($value, $i);
      }
    }
    // 1-2 spacing values
    @else {
      $positions: map-get($axis-positions, $axis);

      @each $position in $positions {
        #{str-slice($property, 1, -3)}-#{$position}: $value;
      }
    }
  }
  // single spacing value
  @else {
    #{$property}: $value;
  }
}

// A mixin that applies responsive spacing from a map
// Usage: @include u-set-spacing-style('link-icon-left');
@mixin u-set-spacing-style($component) {
  $group: map-get($component-spacing, $component);

  @each $property, $value in $group {
    $is-breakpoint: variable-exists('breakpoints') and
      map-has-key($breakpoints, $property) and
      type-of($value) ==
      'map';

    @include u-set-breakpoint(if($is-breakpoint, $property, null)) {
      @if ($is-breakpoint) {
        $subproperties: $value;
        @each $subproperty, $subvalue in $subproperties {
          @include u-create-spacing-properties($subproperty, $subvalue);
        }
      } @else {
        @include u-create-spacing-properties($property, $value);
      }
    }
  }
}
