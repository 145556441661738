@import 'src/sass/uhc/tokens/uhc.scss';
@import 'src/sass/helpers/variables';
@import 'src/sass/helpers/mixins';

.fieldWrapper {
    position: relative;
    background-color: #fff;
    outline: 1px solid $rds-color-neutral-50;
    outline-offset: unset;

    &.isFocused {
        outline: 2px solid $focus-ring-color;
    }

    &.hasError {
        outline: 2px solid #f04f2a;
    }
}

.field {
    -o-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #000;
    font-size: $rds-font-size-16;
    font-weight: $rds-font-weight-semibold;
    padding: 0 $rds-spacing-size-32 0 $rds-spacing-size-16;
    border: none;
    height: 40px;
    display: block;
    width: 100%;
    min-width: 145px;
    background-color: transparent;
    position: relative;
    cursor: pointer;

    @include focus-highlight {
        outline: none;
    }
}

.icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: $rds-spacing-size-16;
    z-index: 2;
}
