@import '../../sass/helpers/variables';
@import '../../sass/helpers/mixins';

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.headerText {
    margin: 0;
}

.headerButtonWrapper {
    display: flex;
    align-items: center;
}

.headerIcon {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
}

.body {
    background: $white;
}

.titleButtonWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.titleButton {
    text-decoration: none;
    color: $blue;
    width: 100%;

    &:focus {
        outline: $blue solid 2px;
        border: none;
    }
}

.titleText {
    text-align: left;
    max-height: 100%;
    flex-grow: 1;
}

.titleIcon {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
}
